.footerwrap {
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    height:60px;
    width:100%;
    bottom:0;
    background-color:white;
    position:fixed;
    bottom:0;
    padding-left:15px;
    padding-right:15px;
}

.fbg {
    display:flex;
    flex-direction: row;
    flex:1;
}
.fb {
    flex:1;
    margin:5px;
    background-color: aliceblue;
    display:flex;
    justify-content: center;
    align-items: center;
    height:50px;
    border-radius: 5px;;
}
