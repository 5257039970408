.orderwrap {
    width:100%;
    padding:20px;
}

.orderitem {
    margin-bottom:20px;
}
.orderdescription {
    font-size:20px;
}

.orderbutton {
    width:150px;
    height:150px;
    background:#cdcdcd;
    margin:10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding:10px;
    border-radius:10px;
    font-size:10px;
}
.orderbutton2 {
    width:150px;
    height:50px;
    background:#44b388;
    background:#512e70;
    color:white;
    margin:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding:10px;
    border-radius:10px;
    font-size:14px;
    font-weight:bold;
    cursor:'pointer';
    cursor:pointer;
}

.ordermultiwrap {
    display:flex;
    flex-direction:row;
}
.ordernoteswrap {
    width:100%;
    padding:20px;
}
.ordernotestextarea {
    width:50%;
    height:100px;
    border:1px solid #dedede;
    padding:5px;
    border-radius:4px;
}
.symptomtitle {
    margin-bottom:20px;
    font-size:18px;
    font-weight:bold;
}

.treatmentlist {
    width:100%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}
.orderssubmit {
    width:100%;
}

.obtextwrap {
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
}
.obtitle {
    font-weight:bold;
    margin-bottom:5px;
}
.obbuttonwrap {
height:30px;
width:100%;
background-color:'blue';
display:flex;
justify-content: center;
}
.obaction {
background-color:lightblue;
flex:1;
display:flex;
justify-content: center;
align-items:center;
margin:2px;
border-radius:3px;
cursor:pointer;
}
.modalwrap {
}
.modalinterior {
    width: 100%;
    height:100%;
    display:flex;
    flex-direction:column;
    border-radius:10px;

}
.modaltop {
    flex:1;
}
.modalbottom {
    margin-top:25px;
}
.modalbuttons {
    display:flex;
    flex-direction:column;
}
.mb {
    flex:1;
    margin:8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:5px;
    background:#efefef;
    padding:5px;
    padding-top:15px;
    padding-bottom:15px;
    cursor:pointer;
}
.modaltitle {
    font-size:22px;
    font-weight:bold;
    margin-bottom:15px;
    text-align:center;
}
.modaldesc {
    margin-bottom:10px;
    text-align:center;
}

.modalselectrow {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}

.loader {
    width: 28px;
    height: 28px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

.sendmeacopywrap {
    margin-left:10px;
    flex-direction:row;
    display:flex;
    align-items:center;

}

.preferbox {
    margin-bottom:10px;

}

.modalSpecialist {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.modalbox {
    top:50%;
    left:50%;
    position:absolute;
    background:white;
    border-radius:5px;
    padding:15px;
    margin-right:-50%;
    transform: translate(-50%, -50%);
    width:600px;
    max-width:50%;
    overflow-y:auto;
    height:600px;
    max-height:50%;
    box-shadow: -5px -5px 15px rgba(255,255,255,0.5),6px 6px 15px rgba(70,70,70,0.12);

}

.durationbuttonwrap {
    width:50%;
    padding-top:50%;
    position:relative;
}
.durationbutton {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:5px;
    background:#44b388;
    padding:5px;
    cursor:pointer;
    flex-direction:column;
}

.preferselection {
    margin-left: 10px;
    background: lightblue;
    padding: 15px;
    border-radius: 5px;
}
@media(max-width:767px) {
    .orderwrap {
        padding:10px;
    }
    .orderitem {
        width:calc(50vw - 30px);
        height:calc(50vw - 30px);
        margin-right:10px;
    }
    .orderbutton {
        width:100%;
        height:100%;
        margin-right:10px;
        
    }
    .modalbox {
        top:50px;
        left:10px;
        bottom:30px;
        right:10px;
        transform:none;
        max-width:calc(100% - 20px);
        max-height:100%;
        width:initial;
        height:initial;
    }
    .ordernotestextarea{
        width:100%;
    }
    .orderbutton2 {
        width:calc(100% - 40px);
        margin-left:20px;
    }
    .ordersubmit {
        margin-bottom:20px;
    }
    
}
