.homeactionbutton {
    background:lightblue;
    background:white;
    color:white;
    width: 300px;
    height:200px;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:20px;
    border-radius:5px;
    margin-top:20px;
    cursor:pointer;
    box-shadow: rgba(0, 0, 82, 0.15) 4px 3px 11px 0px;
}

