.header {
    width:100%;
    display:flex;
    height:60px;
    justify-content: center;
    align-items: center;
    background-color:white;
    position:fixed;
    top:0;
    z-index:9999;
    flex-direction:column;
}

.headerTitle {
    font-size:32px;
    font-weight:800;
    color:#404040
}
.ordernotestitle {
    font-weight:bold;
}
@media(max-width:767px) {
    .headerTitle {
    font-size:18px;
}
}