.orderwrap {
    width:100%;
    padding:20px;
}

.orderitem {
    margin-bottom:20px;
}
.orderdescription {
    font-size:20px;
}

.orderbutton {
    width:150px;
    height:150px;
    background:#cdcdcd;
    margin:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding:10px;
    border-radius:10px;
    font-size:10px;
    
}
.obtextwrap {
    flex:1;
}

.obbuttonwrap {
height:50px;
width:100%;
background-color:'blue';
}
.ordermultiwrap {
    display:flex;
    flex-direction:row;
}
.ordernoteswrap {
    width:100%;
    padding:20px;
}
.ordernotestextarea {
    width:50%;
    height:100px;
}