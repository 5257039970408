.twrap {
    display:flex;
    flex-direction:row;
}

.tcontainer {
    display:table;
    width:100%;
}

.tcontainer > * {
    display: table-row;
}
.twrap {
    font-size:11px;
    padding:2px;
}
.tcontainer > .twrap > .tcol {
    display:table-cell;
    vertical-align: center;
    padding:3px;
}

.tgroup {
    margin-bottom:15px;
}


.tagroup {
    display:flex;
    flex-direction:row;
}

.tattribute {
    background-color:#f3f3f3;
    padding:5px;
    margin:6px;
    border-radius:4px;
    position:relative;
}

.taclose {
    position:absolute;
    top:-5px;
    right:-5px;
    background:red;
    cursor:pointer;
    width:20px;
    height:20px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:10px;
    color:white;
}
.ttextarea {
    width:500px;
    height:200px;
}
.tbottom {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}
.tbottomgroup {

}

.theverytop {
    display: flex;
    justify-content: space-between;
}

.bgth {
    text-align:center;
}

.bgtr {
    text-align:center;
    padding:10px;
    border:1px solid #dedede;
}

.bgwrap {
    background: #dedede;
    padding: 15px;
}
.bgtitle {
    font-weight:bold;
}
.bginput {
    margin-bottom:10px;
}
.bgmaintitle {
    margin-bottom:10px;
    font-size:26px;
    font-weight:bold;
}

.detailstitle {
    font-weight:bold;
}

.detailsinput {
    margin-bottom:10px;
}