.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

tr:hover {
  background:#f1f1f1;
  cursor:pointer;
}

.mainwindow {
  padding:25px;

}

.button {
  background:#b6b0b0;
  padding:8px 12px 8px 12px;
  cursor:pointer;
  margin-right:5px;
  border-radius:3px;
  display:inline-block;
}

.buttonrow {
  display:flex;
  flex-direction:row;
  align-items: center;
}
.editform {
  width:100%;
  background:#f1f1f1;
  padding:15px;
  height:100%;
}
.edititem {
  margin-bottom:10px;
}
.editinput {
  background:#e1e1e1;
  border:1px solid #f1f1f1;
  margin:2px;
  padding:3px;
  border-radius: 3px;
  width:100%;
}
.textedit {
  height:100px;
}
.inputtitle {
  color:#303030;
  margin-bottom:10px;
  font-weight:bold;
}

.availselectedwrap {
  margin-bottom:15px;
}
.availnotselectedwrap {
  margin-bottom:15px;
}
.availnotselected {
  margin-bottom:5px;
}

.availselected {
  margin-bottom:5px;
}
.detailsrow {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}
.detailswrap {
  background:#b6b0b0;
  padding:4px;
  border-radius:3px;
  margin:5px;
}

.detailsitem {

}
.adddetailwrap {
  width:100%;
  display:flex;
  flex-direction:row;
}
.adddetailitem {
  flex:1;
  display:flex;
  flex-direction:column;
  margin:5px;
}
.adddetaillabel {
  font-weight:bold;
}
.adddetailinput {
  width:100%;
  background:#e1e1e1;
}
.detaildelete {
  cursor:pointer;
}
.bottom {
  padding-top:10px;
  border-top:1px solid #303030;
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  width:100%;
}

.editwrap {
  width:100%;
  display:flex;
  flex-direction:row;
  margin-bottom:15px;
  margin-top:15px;
}
.editcol {
  flex:1;

}