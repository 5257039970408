.welcomeWrap {
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex:1;
    background: rgb(55,83,97);
    background: linear-gradient(0deg, rgba(55,83,97,1) 0%, rgba(60,114,140,1) 48%, rgba(41,121,144,1) 100%);
    /* background:#fafafc; */
    background:#dee0e7;
}
.welcomeBox {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:#fefefe;
    padding:15px;
    border-radius:10px;

}


.ibw {
    width:80%;
}

@media(max-width:767px) {
    .ibw {
        width:100%;
    }
}