.wellnesswrap {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
}
.wellnessitems {
    width:60%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
}
.wellnessitem {
    margin:4px;
    padding:8px;
    border-radius:4px;
    cursor:pointer;
}

.wellnessclear {
    cursor:pointer;
    margin-top:25px;
}