.mainScreenWrap {
    width:100%;
    height:100%;
    background-color:white;
    border-radius:10px;
    display:flex;
    flex-direction:column;
}

.contentBox {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex:1;
    background:#ffe1a2;
    background:#fefefe;
}
.screenTitleBig {
    font-size:36px;
    font-weight:bold;
    margin-bottom:20px;
}

.screenDescription {
    max-width:400px;
    text-align:center;
}

.radioGroup {
    display:flex;
    flex-direction:row;
    padding:15px;
}
.radioButton > label {
    display:flex;
    flex-direction: column;
    padding:15px;
}

.insidewrapper {
    flex:1;
    display:flex;
    justify-content: center;
    align-items: center;
    padding-top:60px;
    padding-bottom:60px;
    background:#fefefe;
}
