.adminOrderWrap {
    background:#dedede;
    padding:15px;
    margin-top:10px;
    border-radius:4px;
}
.adminOrderGroup {
    margin-bottom:15px;
}
.adminOrderTitle {
    font-weight:bold;
}
.adminOrderSub {
    text-transform: uppercase;;
}