.symwrap {
    width:100%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: center;
    padding:10px;
}
.symbutton {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    padding:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius:10px;
    box-shadow: -5px -5px 15px rgba(255,255,255,0.5),
            6px 6px 15px rgba(70,70,70,0.12);
}

.symbuttonoutline {
    width:calc(33% - 30px);
    padding-top:calc(33% - 30px);
    margin:15px;
    position:relative;
}

@media(max-width:767px) {
    .symbuttonoutline {
        width:calc(50% - 10px);
        padding-top:calc(50% - 10px);
        margin:5px;
    }
    .symbutton {
        font-size:14px;
    }
}